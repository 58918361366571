<template>
    <div>
        <layout-wrapper>
            <div>
                Daftar Sync
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="text-white pull-right btn btn-primary" v-bind="attrs" v-on="on">
                            Sinkron Gambar
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="kind = 'KATALOG', dialog = !dialog">
                            <v-list-item-title>Gambar Produk</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="kind = 'TLC', dialog = !dialog">
                            <v-list-item-title>Gambar Tlc</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <!-- <v-btn class="text-white pull-right btn btn-primary" @click="dialog = !dialog">
                    Sinkron Gambar
                </v-btn> -->
            </div>
            <div class="clearfix"></div>
            <br>
            <div class="wrapp-input-contact m-t-20">
                <template>
                    <div class="list-auto-template">
                        <v-row>
                            <v-col cols="12" class="col col-md-5 pt-0">
                                <v-text-field v-model="term" placeholder="Cari nama file">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" class="col col-md-3 pt-0-mobile mb-4">
                                <v-btn class="mt-2-mobile mr-2 text-white btn btn-primary" @click="handleSearch">
                                    Cari
                                </v-btn>
                                <v-btn @click="resetFilter" class="mt-2-mobile">
                                    Reset
                                </v-btn>
                            </v-col>
                        </v-row>

                        <div class="my-5">
                            <v-data-table class="elevation-1"
                                :headers="headers" 
                                :items="syncs" 
                                :options.sync="options"
                                :server-items-length="totalElements"
                                :loading="isLoading"
                                :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
                            >
                                <template v-slot:item.createdOn="{ item }">
                                    <span>{{ item.time }}</span>
                                </template>
                            </v-data-table>
                        </div>
                    </div>
                </template>
            </div>
        </layout-wrapper>

        <SuccessNotif />

        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog" persistent max-width="400">
                    <v-card>
                        <v-card-title class="text-h5">
                            Sinkron Gambar
                        </v-card-title>
                        <v-card-text>
                            Yakin akan melakukan sinkron gambar ke google image?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="red darken-1" text @click="dialog = false">
                                Batal
                            </v-btn>
                            <v-btn color="green darken-1" text @click="kind == 'KATALOG' ? syncImage() : syncTlc()">
                                Sinkron
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
    </div>
</template>
<script>
    import axios from 'axios'
    import Scrollbar from 'smooth-scrollbar'
    // import ErrorMessage from '../components/ErrorMessage'
    import MainLayout from "../layouts/MainLayout"
    import SuccessNotif from "../components/SuccessNotif"
    import { mapState } from 'vuex'

    import LayoutWrapper from '../layouts/components/LayoutWrapper.vue'

    // const $ = require("jquery")
    const { commonpagesetup, mainuisetup } = require('../utils/ui-setup')
    var moment = require('moment');

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo,
                broadcastCallback: state => state.socket.broadcastCallback,
            })
        },
        data: function () {
            return {
                totalElements: 0,

                kind: String(),
                term: String(),

                headers: [
                    { text: 'Nama File', value: 'filename', sortable: false },
                    { text: 'Jenis', value: 'kind', sortable: false },
                    { text: 'Tanggal Mulai', value: 'startTimeUI', sortable: false },
                    { text: 'Tanggal Selesai', value: 'finishTimeUI', sortable: false },
                    { text: 'Status', value: 'status', sortable: false },
                ],

                syncs: Array(),
                
                options: Object(),

                dialog: false,
                isLoading: false,
            }
        },
        watch: {
            options: {
                handler() {
                    this.loadSync();
                },
                deep: true,
            },
            sessionInfo: function (val) {
                if (!this.$helpers.isBlank(val) && val.isLoggedIn) {
                    this.loadInitialData();
                }
            },
        },
        methods: {
            handleSearch: async function() {
                this.options.page = 1
                await this.loadSync()
            },

            resetFilter: async function() {
                this.term = String()

                await this.loadSync()
            },

            loadInitialData: async function () {
                this.loadSync()
            },

            syncImage: async function() {
                try {
                    this.isLoading = true

                    await axios.put('https://img-search.bippotalk.com/products/sync-update', Object(), {
                        params: {
                            page: 0,
                            size: 25
                        }
                    })

                    this.isLoading = false
                } catch (error) {
                    this.isLoading = false
                    console.log("sinkron-image", error);
                }
            },

            syncTlc: async function() {
                try {
                    this.isLoading = true

                    await axios.get('https://img-search.bippotalk.com/products-tlc/download')

                    this.isLoading = false
                } catch (error) {
                    this.isLoading = false
                    console.log("sinkron-image", error);
                }
            },

            loadSync: async function() {
                this.isLoading = true
                let params = Object();

                params.term = this.term

                if (this.options && this.options.page)  params.page = this.options.page;
                if (this.options && this.options.itemsPerPage) params.size = this.options.itemsPerPage;

                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/sync/product-image', {
                        params
                    })

                    this.syncs = data.content.map(e => {
                        e.startTimeUI = this.utcToLocal(e.startTime, 'MMMM Do, h:mm:ss a')
                        e.finishTimeUI = this.utcToLocal(e.finishTime, 'MMMM Do, h:mm:ss a')

                        return e
                    })
                    
                    this.totalElements = data.page.totalElements
                } catch (error) {
                    console.log("load-sync", error);
                }
                this.isLoading = false
            },

            utcToLocal: function(date, format) {
                var utcLocal = moment(date).local().format(format);
                return utcLocal;
            },

            utcToLocalView: function(date, format) {
                var utcLocal = moment(date).format(format);
                return utcLocal;
            },

            localToUtc: function(date, format) {
                var utcLocal = moment(date).utc().format(format);
                return utcLocal;
            },
        },
        created: function () {
            this.$emit('update:layout', MainLayout);
        },
        mounted: function () {
            Scrollbar.initAll();
            // if (!this.$helpers.isBlank(this.sessionInfo) && this.sessionInfo.isLoggedIn) {
            //     this.loadInitialData();
            // }
            try {
                mainuisetup();
                commonpagesetup();
            } catch (err) {
                console.log(err);
            }
        },
        components: {
            'layout-wrapper': LayoutWrapper,
            // ErrorMessage,
            SuccessNotif,
        }
    }
</script>